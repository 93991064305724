/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import { CryptoMoneyFromJSON, CryptoMoneyFromJSONTyped, CryptoMoneyToJSON } from './CryptoMoney';
import type { CryptoTransferStatus } from './CryptoTransferStatus';
import {
  CryptoTransferStatusFromJSON,
  CryptoTransferStatusFromJSONTyped,
  CryptoTransferStatusToJSON,
} from './CryptoTransferStatus';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
} from './CryptoWalletAddressWithTag';

/**
 *
 * @export
 * @interface CryptoTransfer
 */
export interface CryptoTransfer {
  /**
   * Transaction id identifying exact transaction in blockchain
   * @type {string}
   * @memberof CryptoTransfer
   */
  blockchainTransactionHash?: string;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof CryptoTransfer
   */
  destination: CryptoWalletAddressWithTag;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof CryptoTransfer
   */
  id: string;
  /**
   *
   * @type {CryptoMoney}
   * @memberof CryptoTransfer
   */
  receivedCrypto?: CryptoMoney;
  /**
   *
   * @type {CryptoTransferStatus}
   * @memberof CryptoTransfer
   */
  status: CryptoTransferStatus;
}

/**
 * Check if a given object implements the CryptoTransfer interface.
 */
export function instanceOfCryptoTransfer(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'destination' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function CryptoTransferFromJSON(json: any): CryptoTransfer {
  return CryptoTransferFromJSONTyped(json, false);
}

export function CryptoTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransfer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    blockchainTransactionHash: !exists(json, 'blockchain_transaction_hash')
      ? undefined
      : json['blockchain_transaction_hash'],
    destination: CryptoWalletAddressWithTagFromJSON(json['destination']),
    id: json['id'],
    receivedCrypto: !exists(json, 'received_crypto') ? undefined : CryptoMoneyFromJSON(json['received_crypto']),
    status: CryptoTransferStatusFromJSON(json['status']),
  };
}

export function CryptoTransferToJSON(value?: CryptoTransfer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    blockchain_transaction_hash: value.blockchainTransactionHash,
    destination: CryptoWalletAddressWithTagToJSON(value.destination),
    id: value.id,
    received_crypto: CryptoMoneyToJSON(value.receivedCrypto),
    status: CryptoTransferStatusToJSON(value.status),
  };
}
