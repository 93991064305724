/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CryptoWalletAddressWithTag
 */
export interface CryptoWalletAddressWithTag {
  /**
   * A string representing a crypto wallet address.
   * @type {string}
   * @memberof CryptoWalletAddressWithTag
   */
  address: string;
  /**
   * A tag/memo required for blockchains supporting pooled addresses
   * @type {string}
   * @memberof CryptoWalletAddressWithTag
   */
  tag?: string;
}

/**
 * Check if a given object implements the CryptoWalletAddressWithTag interface.
 */
export function instanceOfCryptoWalletAddressWithTag(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'address' in value;

  return isInstance;
}

export function CryptoWalletAddressWithTagFromJSON(json: any): CryptoWalletAddressWithTag {
  return CryptoWalletAddressWithTagFromJSONTyped(json, false);
}

export function CryptoWalletAddressWithTagFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CryptoWalletAddressWithTag {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    address: json['address'],
    tag: !exists(json, 'tag') ? undefined : json['tag'],
  };
}

export function CryptoWalletAddressWithTagToJSON(value?: CryptoWalletAddressWithTag | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    address: value.address,
    tag: value.tag,
  };
}
