/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON } from './FiatMoney';
import type { LiquidityProvider } from './LiquidityProvider';
import {
  LiquidityProviderFromJSON,
  LiquidityProviderFromJSONTyped,
  LiquidityProviderToJSON,
} from './LiquidityProvider';
import type { PaymentIntentAutomaticRefund } from './PaymentIntentAutomaticRefund';
import {
  PaymentIntentAutomaticRefundFromJSON,
  PaymentIntentAutomaticRefundFromJSONTyped,
  PaymentIntentAutomaticRefundToJSON,
} from './PaymentIntentAutomaticRefund';
import type { PaymentIntentFailureReason } from './PaymentIntentFailureReason';
import {
  PaymentIntentFailureReasonFromJSON,
  PaymentIntentFailureReasonFromJSONTyped,
  PaymentIntentFailureReasonToJSON,
} from './PaymentIntentFailureReason';
import type { PaymentIntentMerchantRefund } from './PaymentIntentMerchantRefund';
import {
  PaymentIntentMerchantRefundFromJSON,
  PaymentIntentMerchantRefundFromJSONTyped,
  PaymentIntentMerchantRefundToJSON,
} from './PaymentIntentMerchantRefund';
import type { PaymentIntentOrder } from './PaymentIntentOrder';
import {
  PaymentIntentOrderFromJSON,
  PaymentIntentOrderFromJSONTyped,
  PaymentIntentOrderToJSON,
} from './PaymentIntentOrder';
import type { PaymentIntentStatus } from './PaymentIntentStatus';
import {
  PaymentIntentStatusFromJSON,
  PaymentIntentStatusFromJSONTyped,
  PaymentIntentStatusToJSON,
} from './PaymentIntentStatus';
import type { TrafficProvider } from './TrafficProvider';
import { TrafficProviderFromJSON, TrafficProviderFromJSONTyped, TrafficProviderToJSON } from './TrafficProvider';

/**
 *
 * @export
 * @interface PaymentIntent
 */
export interface PaymentIntent {
  /**
   *
   * @type {Array<PaymentIntentAutomaticRefund>}
   * @memberof PaymentIntent
   */
  automaticRefunds?: Array<PaymentIntentAutomaticRefund>;
  /**
   * Opaque object identifier
   * @type {string}
   * @memberof PaymentIntent
   */
  cryptoTransferId?: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentIntent
   */
  expiresAt: Date;
  /**
   *
   * @type {PaymentIntentFailureReason}
   * @memberof PaymentIntent
   */
  failureReason?: PaymentIntentFailureReason;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof PaymentIntent
   */
  id: string;
  /**
   *
   * @type {PaymentIntentMerchantRefund}
   * @memberof PaymentIntent
   */
  merchantRefund?: PaymentIntentMerchantRefund;
  /**
   *
   * @type {PaymentIntentOrder}
   * @memberof PaymentIntent
   */
  order?: PaymentIntentOrder;
  /**
   *
   * @type {PaymentIntentStatus}
   * @memberof PaymentIntent
   */
  status: PaymentIntentStatus;
  /**
   *
   * @type {TrafficProvider}
   * @memberof PaymentIntent
   */
  trafficProvider: TrafficProvider;
  /**
   *
   * @type {LiquidityProvider}
   * @memberof PaymentIntent
   */
  liquidityProvider?: LiquidityProvider;
  /**
   *
   * @type {FiatMoney}
   * @memberof PaymentIntent
   */
  requestedFiat: FiatMoney;
  /**
   * URI
   * @type {string}
   * @memberof PaymentIntent
   */
  successUrl: string;
  /**
   * URI
   * @type {string}
   * @memberof PaymentIntent
   */
  failureUrl: string;
}

/**
 * Check if a given object implements the PaymentIntent interface.
 */
export function instanceOfPaymentIntent(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'expiresAt' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'trafficProvider' in value;
  isInstance = isInstance && 'requestedFiat' in value;
  isInstance = isInstance && 'successUrl' in value;
  isInstance = isInstance && 'failureUrl' in value;

  return isInstance;
}

export function PaymentIntentFromJSON(json: any): PaymentIntent {
  return PaymentIntentFromJSONTyped(json, false);
}

export function PaymentIntentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentIntent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    automaticRefunds: !exists(json, 'automatic_refunds')
      ? undefined
      : (json['automatic_refunds'] as Array<any>).map(PaymentIntentAutomaticRefundFromJSON),
    cryptoTransferId: !exists(json, 'crypto_transfer_id') ? undefined : json['crypto_transfer_id'],
    expiresAt: new Date(json['expires_at']),
    failureReason: !exists(json, 'failure_reason')
      ? undefined
      : PaymentIntentFailureReasonFromJSON(json['failure_reason']),
    id: json['id'],
    merchantRefund: !exists(json, 'merchant_refund')
      ? undefined
      : PaymentIntentMerchantRefundFromJSON(json['merchant_refund']),
    order: !exists(json, 'order') ? undefined : PaymentIntentOrderFromJSON(json['order']),
    status: PaymentIntentStatusFromJSON(json['status']),
    trafficProvider: TrafficProviderFromJSON(json['traffic_provider']),
    liquidityProvider: !exists(json, 'liquidity_provider')
      ? undefined
      : LiquidityProviderFromJSON(json['liquidity_provider']),
    requestedFiat: FiatMoneyFromJSON(json['requested_fiat']),
    successUrl: json['success_url'],
    failureUrl: json['failure_url'],
  };
}

export function PaymentIntentToJSON(value?: PaymentIntent | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    automatic_refunds:
      value.automaticRefunds === undefined
        ? undefined
        : (value.automaticRefunds as Array<any>).map(PaymentIntentAutomaticRefundToJSON),
    crypto_transfer_id: value.cryptoTransferId,
    expires_at: value.expiresAt.toISOString(),
    failure_reason: PaymentIntentFailureReasonToJSON(value.failureReason),
    id: value.id,
    merchant_refund: PaymentIntentMerchantRefundToJSON(value.merchantRefund),
    order: PaymentIntentOrderToJSON(value.order),
    status: PaymentIntentStatusToJSON(value.status),
    traffic_provider: TrafficProviderToJSON(value.trafficProvider),
    liquidity_provider: LiquidityProviderToJSON(value.liquidityProvider),
    requested_fiat: FiatMoneyToJSON(value.requestedFiat),
    success_url: value.successUrl,
    failure_url: value.failureUrl,
  };
}
