import { inject, InjectionKey } from 'vue';
import { Api } from './api';

export const clientKey: InjectionKey<Api> = Symbol('api-client');

export function useClient() {
  const router = inject<Api>(clientKey);
  if (!router) {
    throw new Error('client key not provided');
  }

  return router;
}
