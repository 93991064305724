/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
} from './CryptoWalletAddressWithTag';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON } from './FiatMoney';
import type { PaymentIntentMerchantRefundOrder } from './PaymentIntentMerchantRefundOrder';
import {
  PaymentIntentMerchantRefundOrderFromJSON,
  PaymentIntentMerchantRefundOrderFromJSONTyped,
  PaymentIntentMerchantRefundOrderToJSON,
} from './PaymentIntentMerchantRefundOrder';
import type { PaymentIntentMerchantRefundStatus } from './PaymentIntentMerchantRefundStatus';
import {
  PaymentIntentMerchantRefundStatusFromJSON,
  PaymentIntentMerchantRefundStatusFromJSONTyped,
  PaymentIntentMerchantRefundStatusToJSON,
} from './PaymentIntentMerchantRefundStatus';

/**
 *
 * @export
 * @interface PaymentIntentMerchantRefund
 */
export interface PaymentIntentMerchantRefund {
  /**
   * Opaque object identifier
   * @type {string}
   * @memberof PaymentIntentMerchantRefund
   */
  cryptoExecutionId?: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentIntentMerchantRefund
   */
  expiresAt: Date;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof PaymentIntentMerchantRefund
   */
  id: string;
  /**
   *
   * @type {PaymentIntentMerchantRefundOrder}
   * @memberof PaymentIntentMerchantRefund
   */
  order?: PaymentIntentMerchantRefundOrder;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof PaymentIntentMerchantRefund
   */
  refundDestination?: CryptoWalletAddressWithTag;
  /**
   *
   * @type {FiatMoney}
   * @memberof PaymentIntentMerchantRefund
   */
  refundFiat: FiatMoney;
  /**
   *
   * @type {PaymentIntentMerchantRefundStatus}
   * @memberof PaymentIntentMerchantRefund
   */
  status: PaymentIntentMerchantRefundStatus;
}

/**
 * Check if a given object implements the PaymentIntentMerchantRefund interface.
 */
export function instanceOfPaymentIntentMerchantRefund(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'expiresAt' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'refundFiat' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function PaymentIntentMerchantRefundFromJSON(json: any): PaymentIntentMerchantRefund {
  return PaymentIntentMerchantRefundFromJSONTyped(json, false);
}

export function PaymentIntentMerchantRefundFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentMerchantRefund {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cryptoExecutionId: !exists(json, 'crypto_execution_id') ? undefined : json['crypto_execution_id'],
    expiresAt: new Date(json['expires_at']),
    id: json['id'],
    order: !exists(json, 'order') ? undefined : PaymentIntentMerchantRefundOrderFromJSON(json['order']),
    refundDestination: !exists(json, 'refund_destination')
      ? undefined
      : CryptoWalletAddressWithTagFromJSON(json['refund_destination']),
    refundFiat: FiatMoneyFromJSON(json['refund_fiat']),
    status: PaymentIntentMerchantRefundStatusFromJSON(json['status']),
  };
}

export function PaymentIntentMerchantRefundToJSON(value?: PaymentIntentMerchantRefund | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    crypto_execution_id: value.cryptoExecutionId,
    expires_at: value.expiresAt.toISOString(),
    id: value.id,
    order: PaymentIntentMerchantRefundOrderToJSON(value.order),
    refund_destination: CryptoWalletAddressWithTagToJSON(value.refundDestination),
    refund_fiat: FiatMoneyToJSON(value.refundFiat),
    status: PaymentIntentMerchantRefundStatusToJSON(value.status),
  };
}
