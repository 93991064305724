/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import { CryptoMoneyFromJSON, CryptoMoneyFromJSONTyped, CryptoMoneyToJSON } from './CryptoMoney';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON } from './FiatMoney';

/**
 *
 * @export
 * @interface PayoutOrder
 */
export interface PayoutOrder {
  /**
   *
   * @type {CryptoMoney}
   * @memberof PayoutOrder
   */
  crypto: CryptoMoney;
  /**
   *
   * @type {FiatMoney}
   * @memberof PayoutOrder
   */
  fiat: FiatMoney;
  /**
   *
   * @type {CryptoMoney}
   * @memberof PayoutOrder
   */
  networkFee?: CryptoMoney;
}

/**
 * Check if a given object implements the PayoutOrder interface.
 */
export function instanceOfPayoutOrder(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'crypto' in value;
  isInstance = isInstance && 'fiat' in value;

  return isInstance;
}

export function PayoutOrderFromJSON(json: any): PayoutOrder {
  return PayoutOrderFromJSONTyped(json, false);
}

export function PayoutOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    crypto: CryptoMoneyFromJSON(json['crypto']),
    fiat: FiatMoneyFromJSON(json['fiat']),
    networkFee: !exists(json, 'network_fee') ? undefined : CryptoMoneyFromJSON(json['network_fee']),
  };
}

export function PayoutOrderToJSON(value?: PayoutOrder | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    crypto: CryptoMoneyToJSON(value.crypto),
    fiat: FiatMoneyToJSON(value.fiat),
    network_fee: CryptoMoneyToJSON(value.networkFee),
  };
}
