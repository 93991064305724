/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import { CryptoMoneyFromJSON, CryptoMoneyFromJSONTyped, CryptoMoneyToJSON } from './CryptoMoney';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
} from './CryptoWalletAddressWithTag';
import type { PaymentIntentAutomaticRefundOrder } from './PaymentIntentAutomaticRefundOrder';
import {
  PaymentIntentAutomaticRefundOrderFromJSON,
  PaymentIntentAutomaticRefundOrderFromJSONTyped,
  PaymentIntentAutomaticRefundOrderToJSON,
} from './PaymentIntentAutomaticRefundOrder';
import type { PaymentIntentAutomaticRefundReason } from './PaymentIntentAutomaticRefundReason';
import {
  PaymentIntentAutomaticRefundReasonFromJSON,
  PaymentIntentAutomaticRefundReasonFromJSONTyped,
  PaymentIntentAutomaticRefundReasonToJSON,
} from './PaymentIntentAutomaticRefundReason';
import type { PaymentIntentAutomaticRefundStatus } from './PaymentIntentAutomaticRefundStatus';
import {
  PaymentIntentAutomaticRefundStatusFromJSON,
  PaymentIntentAutomaticRefundStatusFromJSONTyped,
  PaymentIntentAutomaticRefundStatusToJSON,
} from './PaymentIntentAutomaticRefundStatus';

/**
 *
 * @export
 * @interface PaymentIntentAutomaticRefund
 */
export interface PaymentIntentAutomaticRefund {
  /**
   * Transaction id identifying exact transaction in blockchain
   * @type {string}
   * @memberof PaymentIntentAutomaticRefund
   */
  blockchainTransactionHash?: string;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof PaymentIntentAutomaticRefund
   */
  id: string;
  /**
   *
   * @type {PaymentIntentAutomaticRefundOrder}
   * @memberof PaymentIntentAutomaticRefund
   */
  order?: PaymentIntentAutomaticRefundOrder;
  /**
   *
   * @type {PaymentIntentAutomaticRefundReason}
   * @memberof PaymentIntentAutomaticRefund
   */
  reason: PaymentIntentAutomaticRefundReason;
  /**
   *
   * @type {CryptoMoney}
   * @memberof PaymentIntentAutomaticRefund
   */
  refundCrypto?: CryptoMoney;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof PaymentIntentAutomaticRefund
   */
  refundDestination?: CryptoWalletAddressWithTag;
  /**
   *
   * @type {PaymentIntentAutomaticRefundStatus}
   * @memberof PaymentIntentAutomaticRefund
   */
  status: PaymentIntentAutomaticRefundStatus;
}

/**
 * Check if a given object implements the PaymentIntentAutomaticRefund interface.
 */
export function instanceOfPaymentIntentAutomaticRefund(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'reason' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function PaymentIntentAutomaticRefundFromJSON(json: any): PaymentIntentAutomaticRefund {
  return PaymentIntentAutomaticRefundFromJSONTyped(json, false);
}

export function PaymentIntentAutomaticRefundFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentAutomaticRefund {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    blockchainTransactionHash: !exists(json, 'blockchain_transaction_hash')
      ? undefined
      : json['blockchain_transaction_hash'],
    id: json['id'],
    order: !exists(json, 'order') ? undefined : PaymentIntentAutomaticRefundOrderFromJSON(json['order']),
    reason: PaymentIntentAutomaticRefundReasonFromJSON(json['reason']),
    refundCrypto: !exists(json, 'refund_crypto') ? undefined : CryptoMoneyFromJSON(json['refund_crypto']),
    refundDestination: !exists(json, 'refund_destination')
      ? undefined
      : CryptoWalletAddressWithTagFromJSON(json['refund_destination']),
    status: PaymentIntentAutomaticRefundStatusFromJSON(json['status']),
  };
}

export function PaymentIntentAutomaticRefundToJSON(value?: PaymentIntentAutomaticRefund | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    blockchain_transaction_hash: value.blockchainTransactionHash,
    id: value.id,
    order: PaymentIntentAutomaticRefundOrderToJSON(value.order),
    reason: PaymentIntentAutomaticRefundReasonToJSON(value.reason),
    refund_crypto: CryptoMoneyToJSON(value.refundCrypto),
    refund_destination: CryptoWalletAddressWithTagToJSON(value.refundDestination),
    status: PaymentIntentAutomaticRefundStatusToJSON(value.status),
  };
}
