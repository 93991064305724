/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Payout status
 * @export
 */
export const PayoutStatus = {
  Canceled: 'CANCELED',
  Completed: 'COMPLETED',
  Created: 'CREATED',
} as const;
export type PayoutStatus = (typeof PayoutStatus)[keyof typeof PayoutStatus];

export function PayoutStatusFromJSON(json: any): PayoutStatus {
  return PayoutStatusFromJSONTyped(json, false);
}

export function PayoutStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutStatus {
  return json as PayoutStatus;
}

export function PayoutStatusToJSON(value?: PayoutStatus | null): any {
  return value as any;
}
