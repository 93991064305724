/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EndUserId
 */
export interface EndUserId {}

/**
 * Check if a given object implements the EndUserId interface.
 */
export function instanceOfEndUserId(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function EndUserIdFromJSON(json: any): EndUserId {
  return EndUserIdFromJSONTyped(json, false);
}

export function EndUserIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): EndUserId {
  return json;
}

export function EndUserIdToJSON(value?: EndUserId | null): any {
  return value;
}
