/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const PaymentIntentAutomaticRefundReason = {
  Expired: 'EXPIRED',
  Overpaid: 'OVERPAID',
  Underpaid: 'UNDERPAID',
  LiquidationRejected: 'LIQUIDATION_REJECTED',
} as const;
export type PaymentIntentAutomaticRefundReason =
  (typeof PaymentIntentAutomaticRefundReason)[keyof typeof PaymentIntentAutomaticRefundReason];

export function PaymentIntentAutomaticRefundReasonFromJSON(json: any): PaymentIntentAutomaticRefundReason {
  return PaymentIntentAutomaticRefundReasonFromJSONTyped(json, false);
}

export function PaymentIntentAutomaticRefundReasonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentAutomaticRefundReason {
  return json as PaymentIntentAutomaticRefundReason;
}

export function PaymentIntentAutomaticRefundReasonToJSON(value?: PaymentIntentAutomaticRefundReason | null): any {
  return value as any;
}
