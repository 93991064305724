/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FiatMoney
 */
export interface FiatMoney {
  /**
   * payment amount. In major units. I.e. 3.55
   * @type {string}
   * @memberof FiatMoney
   */
  amount: string;
  /**
   * Three-letter ISO currency code https://www.iso.org/iso-4217-currency-codes.html
   * @type {string}
   * @memberof FiatMoney
   */
  currency: string;
}

/**
 * Check if a given object implements the FiatMoney interface.
 */
export function instanceOfFiatMoney(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'amount' in value;
  isInstance = isInstance && 'currency' in value;

  return isInstance;
}

export function FiatMoneyFromJSON(json: any): FiatMoney {
  return FiatMoneyFromJSONTyped(json, false);
}

export function FiatMoneyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiatMoney {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amount: json['amount'],
    currency: json['currency'],
  };
}

export function FiatMoneyToJSON(value?: FiatMoney | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount: value.amount,
    currency: value.currency,
  };
}
