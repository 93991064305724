/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentIntentOrderCreate
 */
export interface PaymentIntentOrderCreate {
  /**
   * Crypto currency with optional network identifier attached.
   * List of crypto currencies Simplex might send you:
   * - USDT
   * - TRX
   * - BTC
   * - USDP
   * - AAVE
   * - USDC
   * - UNI
   * - COTI-ERC20
   * - TUSD
   * - COTI
   * - BUSD
   * - HTR
   * - USDT-TRC20
   * @type {string}
   * @memberof PaymentIntentOrderCreate
   */
  cryptoCurrency: string;
}

/**
 * Check if a given object implements the PaymentIntentOrderCreate interface.
 */
export function instanceOfPaymentIntentOrderCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'cryptoCurrency' in value;

  return isInstance;
}

export function PaymentIntentOrderCreateFromJSON(json: any): PaymentIntentOrderCreate {
  return PaymentIntentOrderCreateFromJSONTyped(json, false);
}

export function PaymentIntentOrderCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentOrderCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cryptoCurrency: json['crypto_currency'],
  };
}

export function PaymentIntentOrderCreateToJSON(value?: PaymentIntentOrderCreate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    crypto_currency: value.cryptoCurrency,
  };
}
