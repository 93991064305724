/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EndUserId } from './EndUserId';
import { EndUserIdFromJSON, EndUserIdFromJSONTyped, EndUserIdToJSON } from './EndUserId';
import type { FailureUrl } from './FailureUrl';
import { FailureUrlFromJSON, FailureUrlFromJSONTyped, FailureUrlToJSON } from './FailureUrl';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON } from './FiatMoney';
import type { LiquidityProvider } from './LiquidityProvider';
import {
  LiquidityProviderFromJSON,
  LiquidityProviderFromJSONTyped,
  LiquidityProviderToJSON,
} from './LiquidityProvider';
import type { Merchant } from './Merchant';
import { MerchantFromJSON, MerchantFromJSONTyped, MerchantToJSON } from './Merchant';
import type { OrderId } from './OrderId';
import { OrderIdFromJSON, OrderIdFromJSONTyped, OrderIdToJSON } from './OrderId';
import type { PayoutFailureReason } from './PayoutFailureReason';
import {
  PayoutFailureReasonFromJSON,
  PayoutFailureReasonFromJSONTyped,
  PayoutFailureReasonToJSON,
} from './PayoutFailureReason';
import type { PayoutOrder } from './PayoutOrder';
import { PayoutOrderFromJSON, PayoutOrderFromJSONTyped, PayoutOrderToJSON } from './PayoutOrder';
import type { PayoutStatus } from './PayoutStatus';
import { PayoutStatusFromJSON, PayoutStatusFromJSONTyped, PayoutStatusToJSON } from './PayoutStatus';
import type { SuccessUrl } from './SuccessUrl';
import { SuccessUrlFromJSON, SuccessUrlFromJSONTyped, SuccessUrlToJSON } from './SuccessUrl';
import type { TrafficProvider } from './TrafficProvider';
import { TrafficProviderFromJSON, TrafficProviderFromJSONTyped, TrafficProviderToJSON } from './TrafficProvider';

/**
 *
 * @export
 * @interface Payout
 */
export interface Payout {
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof Payout
   */
  id: string;
  /**
   * Opaque object identifier
   * @type {string}
   * @memberof Payout
   */
  cryptoExecutionId?: string;
  /**
   *
   * @type {EndUserId}
   * @memberof Payout
   */
  endUserId: EndUserId;
  /**
   *
   * @type {Merchant}
   * @memberof Payout
   */
  merchant: Merchant;
  /**
   *
   * @type {PayoutOrder}
   * @memberof Payout
   */
  order?: PayoutOrder;
  /**
   *
   * @type {OrderId}
   * @memberof Payout
   */
  orderId: OrderId;
  /**
   *
   * @type {TrafficProvider}
   * @memberof Payout
   */
  trafficProvider?: TrafficProvider;
  /**
   *
   * @type {LiquidityProvider}
   * @memberof Payout
   */
  liquidityProvider?: LiquidityProvider;
  /**
   *
   * @type {Date}
   * @memberof Payout
   */
  expiresAt: Date;
  /**
   *
   * @type {PayoutFailureReason}
   * @memberof Payout
   */
  failureReason: PayoutFailureReason;
  /**
   * Crypto currency with optional network identifier attached.
   * List of crypto currencies Simplex might send you:
   * - USDT
   * - TRX
   * - BTC
   * - USDP
   * - AAVE
   * - USDC
   * - UNI
   * - COTI-ERC20
   * - TUSD
   * - COTI
   * - BUSD
   * - HTR
   * - USDT-TRC20
   * @type {string}
   * @memberof Payout
   */
  requestedCryptoCurrency: string;
  /**
   *
   * @type {FiatMoney}
   * @memberof Payout
   */
  requestedFiat: FiatMoney;
  /**
   *
   * @type {PayoutStatus}
   * @memberof Payout
   */
  status: PayoutStatus;
  /**
   * A message to the end-user.
   * @type {string}
   * @memberof Payout
   */
  memo?: string;
  /**
   *
   * @type {SuccessUrl}
   * @memberof Payout
   */
  successUrl: SuccessUrl;
  /**
   *
   * @type {FailureUrl}
   * @memberof Payout
   */
  failureUrl: FailureUrl;
}

/**
 * Check if a given object implements the Payout interface.
 */
export function instanceOfPayout(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'endUserId' in value;
  isInstance = isInstance && 'merchant' in value;
  isInstance = isInstance && 'orderId' in value;
  isInstance = isInstance && 'expiresAt' in value;
  isInstance = isInstance && 'failureReason' in value;
  isInstance = isInstance && 'requestedCryptoCurrency' in value;
  isInstance = isInstance && 'requestedFiat' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'successUrl' in value;
  isInstance = isInstance && 'failureUrl' in value;

  return isInstance;
}

export function PayoutFromJSON(json: any): Payout {
  return PayoutFromJSONTyped(json, false);
}

export function PayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payout {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    cryptoExecutionId: !exists(json, 'crypto_execution_id') ? undefined : json['crypto_execution_id'],
    endUserId: EndUserIdFromJSON(json['end_user_id']),
    merchant: MerchantFromJSON(json['merchant']),
    order: !exists(json, 'order') ? undefined : PayoutOrderFromJSON(json['order']),
    orderId: OrderIdFromJSON(json['order_id']),
    trafficProvider: !exists(json, 'traffic_provider') ? undefined : TrafficProviderFromJSON(json['traffic_provider']),
    liquidityProvider: !exists(json, 'liquidity_provider')
      ? undefined
      : LiquidityProviderFromJSON(json['liquidity_provider']),
    expiresAt: new Date(json['expires_at']),
    failureReason: PayoutFailureReasonFromJSON(json['failure_reason']),
    requestedCryptoCurrency: json['requested_crypto_currency'],
    requestedFiat: FiatMoneyFromJSON(json['requested_fiat']),
    status: PayoutStatusFromJSON(json['status']),
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    successUrl: SuccessUrlFromJSON(json['success_url']),
    failureUrl: FailureUrlFromJSON(json['failure_url']),
  };
}

export function PayoutToJSON(value?: Payout | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    crypto_execution_id: value.cryptoExecutionId,
    end_user_id: EndUserIdToJSON(value.endUserId),
    merchant: MerchantToJSON(value.merchant),
    order: PayoutOrderToJSON(value.order),
    order_id: OrderIdToJSON(value.orderId),
    traffic_provider: TrafficProviderToJSON(value.trafficProvider),
    liquidity_provider: LiquidityProviderToJSON(value.liquidityProvider),
    expires_at: value.expiresAt.toISOString(),
    failure_reason: PayoutFailureReasonToJSON(value.failureReason),
    requested_crypto_currency: value.requestedCryptoCurrency,
    requested_fiat: FiatMoneyToJSON(value.requestedFiat),
    status: PayoutStatusToJSON(value.status),
    memo: value.memo,
    success_url: SuccessUrlToJSON(value.successUrl),
    failure_url: FailureUrlToJSON(value.failureUrl),
  };
}
