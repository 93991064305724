/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Payout, ProblemDetails } from '../models/index';
import { PayoutFromJSON, PayoutToJSON, ProblemDetailsFromJSON, ProblemDetailsToJSON } from '../models/index';

export interface GetPayoutRequest {
  id: string;
}

/**
 *
 */
export class PayoutsApi extends runtime.BaseAPI {
  /**
   */
  async getPayoutRaw(
    requestParameters: GetPayoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Payout>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getPayout.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/payouts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayoutFromJSON(jsonValue));
  }

  /**
   */
  async getPayout(
    requestParameters: GetPayoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Payout> {
    const response = await this.getPayoutRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
