/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrafficProvider
 */
export interface TrafficProvider {
  /**
   * Traffic provider id
   * @type {string}
   * @memberof TrafficProvider
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TrafficProvider
   */
  displayName: string;
  /**
   * Traffic provider logo url
   * @type {string}
   * @memberof TrafficProvider
   */
  logoUrl: string;
}

/**
 * Check if a given object implements the TrafficProvider interface.
 */
export function instanceOfTrafficProvider(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'displayName' in value;
  isInstance = isInstance && 'logoUrl' in value;

  return isInstance;
}

export function TrafficProviderFromJSON(json: any): TrafficProvider {
  return TrafficProviderFromJSONTyped(json, false);
}

export function TrafficProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficProvider {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    displayName: json['display_name'],
    logoUrl: json['logo_url'],
  };
}

export function TrafficProviderToJSON(value?: TrafficProvider | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    display_name: value.displayName,
    logo_url: value.logoUrl,
  };
}
